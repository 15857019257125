import type { FilterSpecification, GeoJSONSource, GeoJSONSourceSpecification, LayerSpecification, LngLatLike, MapGeoJSONFeature, MapMouseEvent, Marker } from "maplibre-gl";
import { Map as NPMap, NPMapOptions, NPMapStyleSpecification } from "@npmap/base";
import { MapInfo, ParkInfo, StateInfo } from "../types";
import popup from "./popup";
import { hoverLayer } from "./cards";
import { arrayToGeoJSON } from "../data/Geojson";
import RelatedParks from "..";

const stateFill: LayerSpecification['paint'] = {
    'fill-color': '#3c3333',// '#f7eee8',
    'fill-opacity': [
        "interpolate", ["linear"],
        ["zoom"],
        8, 0.2,
        13, 0.0
    ]
};

const selectedParkLine: LayerSpecification['paint'] = {
    'line-color': '#4e7500',
    'line-width': 2,
};

const selectedParkLineBorder: LayerSpecification['paint'] = {
    'line-color': '#e5f8ce',
    'line-width': 4,
};

const stateFillFilterBase = (stateNames: string[] = []): FilterSpecification =>
    ['!in', 'state_name', ...(stateNames.length ? stateNames : ['NONE'])];

const parkFillFilterBase = (unitCodes: string[] = ['NONE']): FilterSpecification => ['all',
    ['in', 'park_class', 'legislative and mask fill', 'legislative', 'legislative and fee'],
    ['>=', 'polygon_on', 0],
    ['in', 'unit_code', ...unitCodes]
];

export const getFullStyle = (stateList: StateInfo[], parkList: ParkInfo[]): NPMapStyleSpecification => ({
    version: 'NPMap5',
    basemap: ['mapbox://styles/atlas-user/ck58pyquo009v01p99xebegr9'],
    sources: {
        nps_parks: parkSource(parkList)
    },
    layers: [
        ...(getStatesStyle(stateList) || []),
        ...(getParksStyle(parkList) || []),
    ],
});


export const getStatesStyle = (stateList: StateInfo[]): NPMapStyleSpecification['layers'] => ([
    // State lightboxing layer
    {
        id: 'States',
        type: 'fill',
        source: 'composite',
        'source-layer': 'nps_bound_state',
        filter: stateFillFilterBase(stateList.map(s => s.abbreviation)),
        layout: {
            'visibility': stateList.length ? 'visible' : 'none'
        },
        paint: stateFill as any
    }
]);

export const getParksStyle = (parkList: ParkInfo[]): NPMapStyleSpecification['layers'] => ([
    // Park Boundary Highlights
    {
        id: 'selectedParkPolyBorder',
        type: 'line',
        source: 'composite',
        'source-layer': 'nps_bound_poly',
        paint: selectedParkLineBorder,
        filter: parkFillFilterBase(parkList.map(p => p.unitCode)),
    }, {
        id: 'selectedParkPolys',
        type: 'line',
        source: 'composite',
        'source-layer': 'nps_bound_poly',
        paint: selectedParkLine,
        filter: parkFillFilterBase(parkList.map(p => p.unitCode))
    },
    // Selected Park Pins
    // There's two layers here, one for the "hovered" park and one for the rest
    // ['!', ['has', 'point_count']], is used to prevent the points from showing up when clustered
    {
        id: 'selectedParkPinsHighlight',
        type: 'symbol',
        source: 'nps_parks',
        layout: {
            'icon-shielded': true,
            'icon-image': 'dot',
            'icon-symbol-library': 'nps',
            'icon-height': 22,
            'icon-width': 22,
            'icon-size': {
                'type': 'exponential',
                'default': 1,
                'stops': [[0, 0.5], [16, 1]]
            },
            'icon-allow-overlap': true
        },
        filter: ['!', ['has', 'point_count']],
        paint: {
            'icon-color': 'white',
            'icon-shield-color': '#82995f'
        },
    }, {
        id: 'selectedParkPins',
        type: 'symbol',
        source: 'nps_parks',
        layout: {
            'icon-shielded': true,
            'icon-image': 'dot',
            'icon-symbol-library': 'nps',
            'icon-height': 22,
            'icon-width': 22,
            'icon-size': {
                'type': 'exponential',
                'default': 1,
                'stops': [[0, 0.5], [16, 1]]
            },
            'icon-allow-overlap': true,
            'text-field': ['get', 'parkLabel'],
            'text-transform': 'uppercase',
            'text-font': [
                "Frutiger LT Std 55 Roman",
                "Arial Unicode MS Regular"
            ],
            'text-size': {
                'type': 'exponential',
                'default': 1,
                'stops': [[0, 14], [16, 18]]
            },
            'text-variable-anchor': ['left', 'bottom', /*'top',*/ 'right'],
            'text-radial-offset': 0.7,
            'text-justify': 'auto'
        },
        paint: {
            'text-color': '#1e4700',
            'text-halo-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                '#e5f8cd',
                '#e6e1db',
            ],
            'text-halo-width': 2,
            'text-halo-blur': 1,
            'icon-color': 'white',
            'icon-shield-color': '#717f5c',
            'icon-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0,
                1
            ]
        },
        filter: ['!', ['has', 'point_count']],
        popup: {
            options: {
                groupName: 'Selected Parks',
                primaryKeys: ['unitCode'],
                formatter: popup() as any,
            },
            labelField: 'parkFullName',
            body: '{parkFullName}'
        },
    },
    // Cluster Circles and the labels
    // Circle layers don't support text, we need two labels to accomplish this
    {
        id: 'selectedParkClusters',
        type: 'circle',
        source: 'nps_parks',
        filter: ['has', 'point_count'],
        paint: {
            // Use step expressions (https://maplibre.org/maplibre-style-spec/#expressions-step)
            // https://maplibre.org/maplibre-gl-js/docs/examples/cluster/
            'circle-color': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                '#82995f',
                '#717f5c'
            ],
            'circle-radius': [
                'interpolate',
                ['linear'],  // Using linear interpolation
                ['get', 'point_count'],
                1, 15,
                100, 30
            ]
        }
    }, {
        id: 'selectedParkClustersLabel',
        type: 'symbol',
        source: 'nps_parks',
        filter: ['has', 'point_count'],
        paint: {
            'text-color': '#ffffff'
        },
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': [
                "Frutiger LT Std 55 Roman",
                "Arial Unicode MS Regular"
            ],
            'text-size': 14,
            'text-offset': [0, 0.25],
            'text-allow-overlap': true,
            'icon-allow-overlap': true
        }
    }]
);

const parkSource = (parkTable: ParkInfo[]): GeoJSONSourceSpecification => ({
    type: 'geojson',
    data: arrayToGeoJSON(parkTable, { primaryKey: 'unitCode' }),
    cluster: true,
    clusterMaxZoom: 14, // Max zoom to cluster points on
    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
});

export const addCenterMarker = (map: NPMap): Marker => {
    const centerMarker = document.createElement('div');
    centerMarker.className = 'maplibregl-user-location-dot';
    const mapLibrary = map._maplibrary;
    return new mapLibrary.Marker({ element: centerMarker });
}

// These are style changes to the basemap to improve the cartography for this specific map
export const initialMapChanges = (map: NPMap): NPMap => {
    // set minZoom to 1 (so we don't get the other side of the globe)
    map.setMinZoom(1);

    // Disable all park labels
    deemphasizeParkLabels(map);

    // Update the city and state labels to look better
    updateCityAndStateLabels(map);

    // Ensure the park labels are on top
    bringParkLabelsToFront(map);

    // Setup interactivity
    setupMapInteractivity(map);

    // Apply background and layer styling
    applyMapStyling(map);

    // Add accessibility for the selectedParkClusters layer
    addAccessibilityToClusters(map);

    // Add the states background layer (make all the states lighter than the rest of the map)
    addStatesBackgroundLayer(map);

    // Allow Popups to be smaller
    // maplibregl-popup-content
    const css = document.createElement('style');
    css.innerHTML = '.maplibregl-popup-content { min-width: 250px; }';
    document.head.appendChild(css);

    return map;
};

const deemphasizeParkLabels = (map: NPMap) => {
    const parkLabelIds = getLayersInGroup(map, 'mapbox:group', '152c77fcd50113a4722701be84d938d2');
    Object.keys(parkLabelIds).forEach(id => {
        //map.setLayoutProperty(id, 'visibility', 'none');
        //for future: determine the property that controls min and max zooms and suppress visibility of these layers to high zooms
        try {
            map.setPaintProperty(id, 'text-color', '#849a67');
            map.setLayoutProperty(id, 'text-size', 10);
        } catch (error) {
            console.info(`NPMap5 warning: 🟡  deemphasizeParkLabels: Layer '${id}' is a line, but attempted to set 'text-color' & 'text-size'`);
        }
    });
};

const updateCityAndStateLabels = (map: NPMap) => {
    const stateLabelIds = getLayersInGroup(map, 'mapbox:group', '7ea41e764441e570249ed73b23a52a0c');
    const cityLabelIds = getLayersInGroup(map, 'mapbox:group', '32a142aab6ac54cf13a492395e7c4176');
    const combinedLabelIds = { ...stateLabelIds, ...cityLabelIds };

    Object.keys(combinedLabelIds).forEach((id) => {
        try {
            map.setPaintProperty(id, 'text-color', '#666666');
            map.setPaintProperty(id, 'text-halo-color', 'rgba(230,225,219,0.6)');
        } catch (error) {
            console.info(`NPMap5 warning: 🟡  updateCityAndStateLabels: Layer '${id}' is a line, but attempted to set 'text-color'`);
        }
    });
};

const bringParkLabelsToFront = (map: NPMap) => {
    const parkLabelLayers = new Set(Object.entries(map.style._layers).filter(([_, v]) => v.source === 'nps_parks').map(([k]) => k));
    let topLayer: string | undefined = map.style._order[map.style._order.length - 1];

    if (!topLayer || !parkLabelLayers.has(topLayer)) {
        topLayer = undefined;
    }

    for (let i = map.style._order.length - 1; i >= 0; i--) {
        const layerId = map.style._order[i];
        if (parkLabelLayers.has(layerId)) {
            map.moveLayer(layerId, topLayer);
            topLayer = layerId;
        }
    }
};

const setupMapInteractivity = (map: NPMap) => {
    map.on('click', 'selectedParkClusters', zoomToCluster('selectedParkClusters'));
    hoverLayer(map, 'selectedParkPins');
    clusterHover(map, 'selectedParkClusters');
};

const applyMapStyling = (map: NPMap) => {
    map.setPaintProperty('background', 'background-color', 'rgba(182, 177, 171, 1)');
    map.setLayoutProperty('mb road case', 'visibility', 'none');

    // Remove park labels
    Object.entries(map.style._layers)
        .filter(([_, props]) => props && props.sourceLayer === 'nps_carto_label_park' && props.type === 'circle')
        .forEach(([id]) => map.setLayoutProperty(id, 'visibility', 'none'));

    // Remove non-NPS national parks
    const nonNPSParks = ['mb national park', 'mb park cemetery'];
    nonNPSParks.forEach(id => map.setLayoutProperty(id, 'visibility', 'none'));
};

const addAccessibilityToClusters = async (map: NPMap) => {
    const popupListener = await map._cache.getAsync('PopupListener') as (any | undefined);

    if (popupListener) {
        popupListener.addAccessibility('selectedParkClusters', {
            labelField: (feature: any) => `Cluster of ${feature.properties.point_count_abbreviated} Units`
        });

        // Add a popup but set its context to an empty div so it doesn't show up
        popupListener.addPopup('selectedParkClusters', {}, {
            context: document.createElement('div')
        });

        // This is a bug in the NPMap Interactivity plugin where the _container is required for some code to work
        // but you don't get a container when you create a map with a custom context
        // So we have to manually set it
        popupListener._activePopups.get('popup').get('selectedParkClusters').popup._container = map.getContainer();
    }
};

const addStatesBackgroundLayer = (map: NPMap) => {
    map.addLayer({
        id: 'States_Background',
        type: 'fill',
        source: 'composite',
        'source-layer': 'nps_bound_state',
        paint: { 'fill-color': '#f7eee8' /*'rgba(219, 215, 208, 1)'*/ }
    }, 'mb park cemetery');
};

/**
 * Zooms to a cluster when clicked on the map.
 * 
 * @param layerName - The name of the layer containing the cluster.
 * @returns A function that handles the click event on the map.
 */
const zoomToCluster = (layerName: string) => (e: MapMouseEvent): void => {
    const { target: map, point, lngLat } = e;
    const features = map.queryRenderedFeatures(point);
    const firstFeature = features[0];

    if (!firstFeature) {
        return;
    }

    let center: LngLatLike = lngLat;
    const geometry = firstFeature.geometry as unknown as { coordinates: [number, number] } | undefined;

    if (geometry?.coordinates) {
        const [lng, lat] = geometry.coordinates;
        center = { lng, lat };
    }

    const clusterId = firstFeature.properties?.cluster_id;
    if (!clusterId) {
        map.easeTo({ center, zoom: map.getZoom() + 1 });
        return;
    }

    const sourceName = map.getLayer(layerName)?.source;
    const source = sourceName ? (map.getSource(sourceName) as GeoJSONSource) : null;

    if (source && typeof source.getClusterExpansionZoom === 'function') {
        source.getClusterExpansionZoom(clusterId)
            .then(zoom => map.easeTo({ center, zoom }))
            .catch(() => map.easeTo({ center, zoom: map.getZoom() + 1 }));
    } else {
        map.easeTo({ center, zoom: map.getZoom() + 1 });
    }
};

/**
 * Retrieves all layers in a map that belong to a specific group.
 * 
 * @param map - The NPMap instance to search through.
 * @param groupKey - The metadata key used to group layers.
 * @param groupValue - The value of the group key to match.
 * @returns A record of layer IDs and their corresponding properties that belong to the specified group.
 */
const getLayersInGroup = (map: NPMap, groupKey: string, groupValue: string): Record<string, any> => (
    Object.fromEntries(
        Object.entries(map.style._layers)
            .filter(([_, layer]) => layer.metadata && (layer.metadata as any)[groupKey] === groupValue)
    )
);

/**
 * Adds hover effects to a specified cluster layer on the map.
 * 
 * @param map - The NPMap instance where the hover effects should be applied.
 * @param layerId - The ID of the layer to apply hover effects to.
 */
const clusterHover = (map: NPMap, layerId: string) => {
    // Hover function to handle hover effects on cluster features
    const hoverFn = (e: MapMouseEvent & {
        features?: MapGeoJSONFeature[] | undefined;
    }) => {
        // Get the IDs of the features under the mouse pointer
        const hoveredFeatureIds = new Set(
            map.queryRenderedFeatures(e.point, { layers: [layerId] })
                .map(f => f.id)
        );

        // Update the hover state for each feature in the layer
        map.queryRenderedFeatures({ layers: [layerId] }).forEach(feature => {
            map.setFeatureState(
                { source: feature.source, id: feature.id },
                { hover: hoveredFeatureIds.has(feature.id) }
            );
        });
    }

    // Add hover event listeners for mouse enter and leave events
    map.on('mouseenter', layerId, hoverFn);
    map.on('mouseleave', layerId, hoverFn);
};

/**
 * Gets the width of a container element or the window.
 * 
 * @param container - The container to measure. Can be an element ID, an HTMLElement, or undefined.
 * @returns The width of the container in pixels.
 */
function getContainerWidth(container?: string | HTMLElement): number {
    if (!container) {
        return window.innerWidth;
    }

    if (typeof container === 'string') {
        const element = document.getElementById(container);
        return element?.clientWidth ?? window.innerWidth;
    }

    return container.clientWidth;
}

/**
 * Configures the initial style and options for the NPMap instance.
 * 
 * @param mapInfo - The MapInfo object containing configuration details for the map.
 * @param sidebarElement - The HTML element representing the sidebar.
 * @param displaySidebar - A boolean flag to determine if the sidebar should be displayed.
 * @param style - The NPMapStyleSpecification object that defines the map's style.
 * @returns An NPMapOptions object containing the configuration for initializing the map.
 */
export const initialStyle = (
    mapInfo: MapInfo,
    sidebarElement: HTMLElement,
    displaySidebar: boolean,
    style: NPMapStyleSpecification
): NPMapOptions => ({
    container: mapInfo.container,
    center: mapInfo.center,
    zoom: mapInfo.zoom,
    ...(mapInfo.mapOptions || {}),
    style,
    controls: {
        home: {
            homePosition: mapInfo.center,
            zoom: mapInfo.zoom,
        },
        navigation: { position: 'top-left', order: 2, showCompass: false }, // Disable compass, since rotate is off
        fullscreen: { position: 'top-left', order: 3 },
        ...(displaySidebar ? {
            sidebar: {
                position: 'top-left',
                order: 100,
                sidebarElement: sidebarElement,
                largeWidth: mapInfo.largeWidth, //'275px',
                mediumWidth: mapInfo.mediumWidth, //'200px',
                smallWidth: mapInfo.smallWidth, //100%
                fullHeight: '100%',
                showOnLoad: getContainerWidth(mapInfo.container) > mapInfo.maxScreenWidthPxSmall,
                maxWidthForSmall: String(mapInfo.maxScreenWidthPxSmall) + 'px',
                maxWidthForMedium: String(mapInfo.maxScreenWidthPxMedium) + 'px'
            }
        } : {})
    },
    debug: false
}); 